import '../scss/app.scss';

import Theme from "./src/Theme";

import scroll from "./src/scroll";
import helpers from "./src/helpers";
import togglable from "./src/togglable";
import menu from "./src/menu";
import smoothScroll from "./src/smoothScroll";
import gravityForms from "./src/third-party/gravityForms";
import flickitySliders from "./src/third-party/flickity";
import scrollAnimations from "./src/scrollAnimations";
import ajaxLoadText from "./src/loadText";
import { predictiveSearch, search } from './src/search';
import { stickyHeader } from './src/_sticky-header';

/**
 * Load theme JS when init listener is dispatched
 */
window.addEventListener('slate/init', function () {
    let Slate = Theme;
    window.Slate = Slate;

    Slate.setCallables([
        scroll,
        helpers,
        togglable,
        menu,
        search,
        predictiveSearch,
        smoothScroll,
        gravityForms,
        flickitySliders,
        scrollAnimations,
        ajaxLoadText,
        stickyHeader,
    ]);

    Slate.init();
});

/**
 * Dispatch init event listener when DOM is ready
 */
window.addEventListener('DOMContentLoaded', function () {
    window.dispatchEvent(new Event('slate/init'));
});
