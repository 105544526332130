export default function smoothScroll() {
    $(document).on('click', '[data-smooth-scroll-target], [href^="#section-"]', function (e) {
        e.preventDefault();

        let el = $(this),
            target = null;

        if (el.attr('data-smooth-scroll-target')) {
            target = $(document).find(`[data-smooth-scroll="${$(this).attr('data-smooth-scroll-target')}"]`);
        } else {
            target = $(document).find(`${$(this).attr('href')}`);
        }

        if (!target.length) {
            return;
        }

        $('html, body').animate({
            scrollTop: target.offset().top - Slate.elements.header.height
        }, 450);
    });
}
