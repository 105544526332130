import { lockScroll, unlockScroll } from './helpers';
import { closeSearch } from './search';

export function openMenu() {
    closeSearch();

    $('[data-site-menu="opener"]').addClass('active');
    $('[data-site-menu="target"]').fadeIn(Slate.transitions.speed);
}

export function closeMenu() {
    $('[data-site-menu="opener"]').removeClass('active');
    $('[data-site-menu="target"]').fadeOut(Slate.transitions.speed);
}

export default function menu() {
    //---- Toggle
    $(document).on('click', '[data-site-menu="opener"]', function (e) {
        e.preventDefault();

        let button = $(this);
        let wrapper = $('[data-site-menu="target"]');

        if (button.hasClass('active')) {
            unlockScroll();
            closeMenu();
        } else {
            // lockScroll(wrapper[0]);
            openMenu();
        }
    });

    $(document).on('click', '[data-mobile-submenu-toggle]', function (e) {
        e.preventDefault();

        let button = $(this);

        button.toggleClass('active');
        button.parents('li').find('.sub-menu').slideToggle(Slate.transitions.speed);
    });

    //---- Remove inline CSS display from menu on window resize
    $(window).on('resize', function () {
        if (Slate.window.width <= Slate.breakpoints.menu) {
            return;
        }

        $('[data-site-menu="opener"]').removeClass('active');
        $('[data-site-menu="target"]').css('display', '');
    });
}
