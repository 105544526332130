import Flickity from "flickity";
import 'flickity-imagesloaded';
// import 'flickity-as-nav-for';
import { addProgressBar, flickitySettings, slugifySliderName, toggleResizedClass, updateProgressBar, wrapControls } from "./flickityExtensions";

toggleResizedClass(Flickity);

/**
 * Load sliders
 */
export default function flickitySliders() {
    let elements = document.querySelectorAll('[data-slider]');

    for (var i = 0; i < elements.length; i++) {
        let element = elements[i];

        Slate.sliders[slugifySliderName(element, i)] = new Flickity(element, flickitySettings(element));
    };
}
