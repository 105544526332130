import { buttonStartLoadingState, buttonStopLoadingState } from './helpers';

/**
 * Example Ajax function
 */
export default function ajaxLoadText() {
    $(document).on('click', '[data-ajax="load-text"]', function (e) {
        e.preventDefault();

        let button = $(this);
        let wrapper = $(document).find('.ajax-load-box');

        buttonStartLoadingState(button);

        $.ajax({
            url: SlateAjax.ajax_admin_url,
            type: 'post',
            data: {
                action: 'greasynews_load_text',
            },
            success: function (response) {
                if (response.success == true) {
                    wrapper.text(response.data.message);

                    setTimeout(function () {
                        wrapper.text('');
                    }, 1000);
                }

                if (response.success == false) {
                    alert('Sorry, there was an error.');
                }
            },
            error: function () {
                alert('Sorry, there was an error.');
            },
            complete: function () {
                buttonStopLoadingState(button);
            },
        });
    });
}
