
import { buttonStartLoadingState } from './../helpers';

export default function gravityForms() {
    //---- On form submission
    // Scroll to top
    $(document).on('click', '.gform_button, .gform_previous_button, .gform_next_button', function () {
        let form_btn = $(this);

        $('html, body').animate({
            scrollTop: form_btn.closest('.gform_wrapper, .gform_confirmation_wrapper').offset().top - (Slate.elements.header.height + 50)
        }, 450);
    });

    // Add loading state
    $(document).on('click', '.gform_button', function () {
        let form_btn = $(this);

        buttonStartLoadingState(form_btn);
    });

    //---- Class on field wrapper
    let fields = '.gfield--type-name input, .gfield--type-email input, .gfield--type-textarea textarea, .gfield--type-text input';
    let parentFields = '.gform-grid-col, .gfield--type-email, .gfield--type-textarea, .gfield--type-text';

    $(document).on('focus', fields, function () {
        $(this).parents(parentFields).addClass('focused');
    });

    $(document).on('blur', fields, function () {
        if ($(this).val() === '') {
            $(this).parents(parentFields).removeClass('focused');
        }
    });

    //---- After form is rendered via Ajax
    $(document).on('gform_post_render', function (event, form_id, current_page) {
        let form = $(document).find(`#gform_wrapper_${form_id}`);

        form.find('.gform_submission_error').prepend('<span>Error</span>');

        $(fields).each(function () {
            if ($(this).val() !== '') {
                $(this).parents(parentFields).addClass('focused');
            }
        });
    });
}
