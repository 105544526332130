export function slugifySliderName(element, index) {
    return (element.dataset.slider ? element.dataset.slider : `slider_${index + 1}`).replace('-', '_');
}

/**
 * @param {Flickity} Flickity
 */
export function toggleResizedClass(slider) {
    let resize = slider.prototype.resize;

    slider.prototype.resize = function () {
        this.element.classList.remove('flickity-resized');
        resize.call(this);
        this.element.classList.add('flickity-resized');
    };
}

/**
 * @param {Flickity} slider
 */
export function wrapControls(slider) {
    if (slider.prevButton) {
        slider.prevButton.element.innerHTML = Slate.svgs.arrowLeft;
    }

    if (slider.nextButton) {
        slider.nextButton.element.innerHTML = Slate.svgs.arrowRight;
    }

    slider.$element.find('.flickity-button').wrapAll('<div class="flickity__controls"></div>');
}

/**
 * @param {Flickity} slider
 */
function getSlidesInView(slider) {
    let amount = Math.round(slider.size.width / slider.selectedSlide.outerWidth);

    if (Slate.window.width <= 450) {
        amount = amount - 1;
    }

    return amount;
}

/**
 * @param {Flickity} slider
 */
export function addProgressBar(slider) {
    if (!slider.options.progressBar || slider.slides.length <= 1) {
        return;
    }

    let element = document.createElement('div');
    element.classList.add('flickity-progress');

    let innerElement = document.createElement('div');
    innerElement.classList.add('flickity-progress__inner');

    element.append(innerElement);

    let slideCount = slider.slides.length;

    innerElement.style.width = `${(100 / slideCount)}%`;

    slider.viewport.after(element);

    updateProgressBar(slider);
}

/**
 * @param {Flickity} slider
 */
export function updateProgressBar(slider) {
    let innerElement = slider.element.querySelector('.flickity-progress__inner');

    if (!slider.options.progressBar || !innerElement) {
        return;
    }

    let slideCount = slider.slides.length;
    let currentSlide = slider.selectedIndex + 1;

    let slidesInView = getSlidesInView(slider);

    if (slidesInView > 1) {
        slideCount = slideCount - (slidesInView - 1);
    }

    innerElement.style.width = `${(currentSlide / slideCount) * 100}%`;
}

export function flickitySettings(slider) {
    let settings = JSON.parse(slider.dataset.settings ?? '{}'),
        type = slider.dataset.slider ?? '';

    switch (type) {
        default:
            return {
                ...{
                    adaptiveHeight: true,
                    imagesLoaded: true,
                    on: {
                        ready: function () {
                            addProgressBar(this);
                            wrapControls(this);

                            this.element.classList.add('flickity-resized');
                        },
                        scroll: function () {
                            updateProgressBar(this);
                        },
                    },
                },
                ...settings,
            };
    }
}
