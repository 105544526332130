/**
 * Initialise array of animation objects
 */
function initScrollAnimations() {
    Slate.animations.scroll = [];

    let els = Slate.animations.reverse
        ? document.querySelectorAll('[data-animate]')
        : document.querySelectorAll('[data-animate]:not([data-animated])');

    if (!els.length) {
        return;
    }

    for (let i = 0; i < els.length; i++) {
        let el = els[i];

        Slate.animations.scroll.push({
            el: el,
            top: elementOffset(el).top
        });
    }

    doScrollAnimations();
}

/**
 * Loop through animatable elements and apply classes based on scroll position
 */
function doScrollAnimations() {
    if (!Slate.animations.scroll.length) {
        return;
    }

    let animatePoint = scrollAnimationPoint(),
        animatedItems = [];

    for (let i = 0; i < Slate.animations.scroll.length; i++) {
        let item = Slate.animations.scroll[i];

        if (!item.top) {
            continue;
        }

        if (item.top <= animatePoint) {
            item.el.setAttribute('data-animated', '');
            animatedItems.push(i);
        } else if (Slate.animations.reverse) {
            item.el.removeAttribute('data-animated');
        }
    }

    // Remove newly animated items from scroll animations array
    if (!Slate.animations.reverse) {
        for (let i = animatedItems.length - 1; i >= 0; i--) {
            Slate.animations.scroll.splice(animatedItems[i], 1);
        }
    }
}

/**
 * Initialise array of parallax elements
 */
function initParallax() {
    Slate.animations.parallax = [];

    let els = document.querySelectorAll('[data-parallax]');

    if (!els.length) {
        return;
    }

    for (let i = 0; i < els.length; i++) {
        let el = els[i],
            top = elementOffset(el).top,
            height = el.offsetHeight,
            bottom = top + height;

        Slate.animations.parallax.push({
            el: el,
            height: height,
            top: top,
            bottom: bottom
        });
    }

    doParallax();
}

/**
 * Update parallax data attribute based on scroll location
 */
function doParallax() {
    if (!Slate.animations.parallax.length) {
        return;
    }

    for (let i = 0; i < Slate.animations.parallax.length; i++) {
        let item = Slate.animations.parallax[i],
            percent;

        if (elementOnScreen(item.top, item.bottom)) {
            let viewableArea = Slate.window.height + item.height,
                scrollAmount = item.bottom - Slate.scroll.position;

            percent = 1 - (scrollAmount / viewableArea);
        } else {
            if (Slate.scroll.position > item.bottom) {
                percent = 1;
            } else {
                percent = 0;
            }
        }

        // Update custom property for use in CSS
        item.el.style.setProperty('--parallax-percent', percent);
    }
}

/**
 * Initialise array of elements animated in CSS
 */
function initCssAnimations() {
    Slate.animations.css = [];

    let els = document.querySelectorAll('[data-animate-loop]');

    for (let i = 0; i < els.length; i++) {
        let el = els[i],
            top = elementOffset(el).top,
            bottom = top + el.offsetHeight;

        Slate.animations.css.push({
            el: el,
            top: top,
            bottom: bottom
        });
    }

    cssAnimationsPlayPause();
}

/**
 * Play/pause CSS animations dependent on their visibility on the screen
 */
function cssAnimationsPlayPause() {
    if (!Slate.animations.css.length) {
        return;
    }

    for (let i = 0; i < Slate.animations.css.length; i++) {
        let item = Slate.animations.css[i];

        if (!elementOnScreen(item.top, item.bottom)) {
            item.el.classList.add('css-animate-pause');
        } else {
            item.el.classList.remove('css-animate-pause');
        }
    }
}

/**
 * Animation point based on viewport size
 */
function scrollAnimationPoint() {
    return Slate.window.width > 1000
        ? (Slate.window.height * .85) + Slate.scroll.position
        : (Slate.window.height * .95) + Slate.scroll.position;
}

/**
 * Get the offset of an object relative to the document
 *
 * @param {object} el
 */
function elementOffset(el) {
    var currentLeftPos = 0,
        currentTopPos = 0;

    if (!el.offsetParent) {
        return {
            top: null,
            left: null,
        }
    }

    do {
        currentLeftPos += el.offsetLeft;
        currentTopPos += el.offsetTop;
    } while (el = el.offsetParent);

    return {
        top: currentTopPos,
        left: currentLeftPos,
    };
}

/**
 * Check if an element is on screen
 *
 * @param {float} top
 * @param {float} bottom
 *
 * @return {bool}
 */
function elementOnScreen(top, bottom) {
    return Slate.scroll.position + Slate.window.height > top && Slate.scroll.position < bottom;
}

/**
 * Scroll animation events
 */
export default function scrollAnimations() {
    initScrollAnimations();
    initParallax();
    initCssAnimations();

    setTimeout(function () {
        refreshAnimations();
    }, 50);

    window.addEventListener('resize', function () {
        initScrollAnimations();
        initParallax();
        initCssAnimations();
    });

    window.addEventListener('scroll', function () {
        window.requestAnimationFrame(doScrollAnimations);
        window.requestAnimationFrame(doParallax);
        window.requestAnimationFrame(cssAnimationsPlayPause);
    }, {
        passive: true,
    });
}

/**
 * Reinitialise scroll animations
 */
export function refreshAnimations() {
    setTimeout(function () {
        initScrollAnimations();
        initParallax();
        initCssAnimations();
    }, 1);
}
