import debounce from "debounce";

function enable(headerElem) {
    let headerHeight = headerElem.outerHeight();

    $('body').addClass('sticky-header');
    $('body').css('padding-top', headerHeight - 1);
}

function disable() {
    $('body').removeClass('sticky-header');
    $('body').css('padding-top', '');
}

function reset(headerElem) {
    disable();
    enable(headerElem);
}

export function stickyHeader() {
    let headerElem = $('.site-header');

    if (!headerElem.length) {
        return;
    }

    enable(headerElem);

    window.onresize = debounce(function () {
        reset(headerElem);
    });
}
