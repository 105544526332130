function addBodyClasses() {
    //---- Scrolling active
    if (Slate.scroll.position > Slate.scroll.offset) {
        Slate.elements.body.element.classList.remove('site-scroll--inactive');
        Slate.elements.body.element.classList.add('site-scroll--active');
    } else {
        Slate.elements.body.element.classList.remove('site-scroll--active');
        Slate.elements.body.element.classList.add('site-scroll--inactive');
    }

    //---- Scrolling up/down
    if (Slate.scroll.previousPosition >= Slate.scroll.position) {
        Slate.elements.body.element.classList.remove('site-scroll--down');
        Slate.elements.body.element.classList.add('site-scroll--up');
    } else {
        Slate.elements.body.element.classList.remove('site-scroll--up');
        Slate.elements.body.element.classList.add('site-scroll--down');
    }
}

export default function scroll() {
    window.addEventListener('load', addBodyClasses);

    window.addEventListener('scroll', addBodyClasses, {
        passive: true,
    });
}
