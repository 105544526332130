import debounce from 'debounce';
import { closeMenu } from './menu';
import { unlockScroll } from './helpers';

export function openSearch() {
    closeMenu();

    $('[data-site-search="opener"]').addClass('active');
    $('[data-site-search="target"]').fadeIn(Slate.transitions.speed);
}

export function closeSearch() {
    $('[data-site-search="opener"]').removeClass('active');
    $('[data-site-search="target"]').fadeOut(Slate.transitions.speed);
}

export function search() {
    $(document).on('click', '[data-site-search="opener"]', function (e) {
        e.preventDefault();

        let button = $(this);
        let wrapper = $('[data-site-search="target"]');

        if (button.hasClass('active')) {
            unlockScroll();
            closeSearch();
        } else {
            openSearch();
            wrapper.find('input').trigger('focus');
        }
    });
}

let runPredictiveSearch = debounce(function (value) {
    let container = $('.site-search');
    let wrapper = $('[data-search-predictive="target"]');
    let list = wrapper.find('.search-results');

    if (!container || !wrapper || !list) {
        return;
    }

    if (value.length < 3) {
        container.removeClass('has-results');
        return;
    }

    $.ajax({
        url: SlateAjax.api_url + '/predictive-search',
        type: 'GET',
        data: {
            search: value,
        },
        dataType: 'json',
        success: function (response) {
            let html = response.html;

            if (html) {
                container.addClass('has-results');
                list.find('li').remove();
                list.append(html);
            } else {
                container.removeClass('has-results');
                list.html('');
            }
        },
    });
}, 350);

export function predictiveSearch() {
    $(document).on('keyup', '[data-search-predictive="input"]', function (e) {
        runPredictiveSearch($(this).val());
    });
}
