import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

/**
 * Lock body scroll
 *
 * @param {HTMLElement} scrollable_element
 */
export function lockScroll(scrollable_element) {
    disableBodyScroll(scrollable_element);
}

/**
 * Clear body scroll lock
 */
export function unlockScroll() {
    clearAllBodyScrollLocks();
}

/**
 * Add loading state to button
 *
 * @param {object} elem
 */
export function buttonStartLoadingState(elem, replacement_text) {
    if (elem.hasClass('btn--has-loader')) {
        return;
    }

    if (replacement_text) {
        elem.attr('original-text', elem.text()).text(replacement_text);
    }

    elem.addClass('btn--has-loader').append(`<span class="btn__icon">${Slate.svgs.spinner}</span>`);
}

/**
 * Remove loading state from button
 *
 * @param {object} elem
 */
export function buttonStopLoadingState(elem) {
    let original_text = elem.attr('original-text');

    elem.removeClass('btn--has-loader').find('.btn__icon').remove();

    if (original_text) {
        elem.text(original_text);
    }
}

export default function helpers() {
    //---- Mute jQuery migrate
    if ($.migrateMute !== undefined) {
        $.migrateMute = true;
    }

    //---- Remove 'no-touch' class for touch devices
    if (Slate.isTouchDevice) {
        $('body').removeClass('no-touch');
    }

    //---- Remove 'no-js' class when JavaScript is enabled
    $('body').removeClass('no-js');

    //---- Turn off autocomplete on datepickers
    $(document).find('.datepicker').attr('autocomplete', 'off');

    //---- Table Wrapper (to make responsive)
    $(document).find('.txt-styles table').wrap('<div class="table-wrap"></div>');

    //---- FitVids
    $(document).find('*:not(.video-wrap) > iframe[src^="https://www.youtube.com"], *:not(.video-wrap) > iframe[src^="https://player.vimeo.com"]').wrap('<div class="video-wrap"></div>');

    //---- Focus on select2 input after opening
    $(document).on('select2:open', function () {
        $(document).find('.select2-search__field').prop('placeholder', 'Search...');
    });

    $('[data-select="page"]').on('change', function () {
        let page_id = $(this).val();

        if (page_id) {
            window.location.href = page_id;
        }
    });
}
